<template>
    <div class="courseWrap" v-if="msg != '课程不存在。'">
        <div class="newlearn">
            <div class="newlearnWrap main clearfix">
                <div :class="course_card_switch == 1 ? 'pic' : 'img'"
                    :style="course_basics.course_images ? 'background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(' + course_basics.course_images + ')' : ''">
                    <img v-if="course_basics.course_images" :src="course_basics.course_images" alt="" />
                    <img v-else :src="require('../../../assets/images/nocourse.png')" alt="" />
                    <img src="./assets/images/jl262.png" alt="" style="position: absolute;height:100%;"
                        v-if="course_basics.is_expired == 1 && course_card_switch == 0" />
                    <img src="./assets/images/jl.png" alt="" style="position: absolute;height:100%;"
                        v-if="course_basics.is_expired == 1 && course_card_switch == 1" />
                    <img src="./assets/images/guoqi262.png" alt="" style="position: absolute;height:100%;"
                        v-if="course_basics.status == 0 && course_card_switch == 0 && user.in_list <= 1" />
                    <img src="./assets/images/guoqi.png" alt="" style="position: absolute;height:100%;"
                        v-if="course_basics.status == 0 && course_card_switch == 1 && user.in_list <= 1" />
                </div>
                <div class="newlearnRight">
                    <h4 class="newlearnRightTitle">
                        {{ course_basics.course_title }}
                    </h4>
                    <h6 class="newlearnRightTime" v-if="course_related != null &&
                        course_related.subtitle.is_show == 1 &&
                        course_related.subtitle.value != ''">
                        {{ course_related.subtitle.value }}
                    </h6>
                    <h6 class="newlearnRightTime"
                        v-if="course_related != null && course_related.course_validity.is_show == 1">
                        <i class="iconfont icon-shizhong"></i> {{ course_related.course_validity.value }}
                    </h6>
                    <div class="newlearnRighttype"
                        v-if="course_related != null && (course_related.course_type.is_show == 1 || course_related.is_required.is_show == 1 || course_related.credit.is_show == 1)">
                        <span v-if="course_related != null && course_related.course_type.is_show == 1"><i
                                class="iconfont icon-courseCate"></i>{{
                                    course_related.course_type.value }}</span>
                        <span v-if="course_related != null && course_related.is_required.is_show == 1"><i
                                class="iconfont icon-courseCate"></i>{{
                                    course_related.is_required.value }}</span>
                        <span class="scoreWrap" v-if="course_related != null && course_related.credit.is_show == 1">
                            <i class="iconfont icon-courseCate"></i>{{ course_related.credit.value }}学分
                        </span>
                    </div>
                    <div class="newlearnRighttype major"
                        v-if="course_related != null && (course_related.subject.is_show == 1 || course_related.specialty.is_show == 1)">
                        <span class="scoreWrap" v-if="course_related != null &&
                            course_related.subject.is_show == 1 &&
                            course_related.subject.value != ''">
                            {{ course_related.subject.value }}
                        </span>
                        <span class="scoreWrap" v-if="course_related != null &&
                            course_related.specialty.is_show == 1 &&
                            course_related.specialty.value != ''">
                            {{ course_related.specialty.value }}
                        </span>
                    </div>
                    <div class="price" v-if="course_claim_info.learning_authority == 0">
                        免费
                    </div>
                    <div class="price"
                        v-if="course_claim_info.learning_authority == 2 && course_basics.is_expired == 0 && course_basics.status == 1 && user.in_list <= 1 && user.user_class_id <= 1">
                        ￥{{ course_claim_info.price }}
                    </div>
                    <div class="price"
                        v-if="course_claim_info.learning_authority == 3 && course_basics.is_expired == 0 && course_basics.status == 1 && user.in_list <= 1">
                        免费
                    </div>
                    <div class="rightBottom">
                        <!-- 未登录 -->
                        <el-button type="primary" round
                            v-if="isLogin == false && course_claim_info.learning_authority == 0 && course_basics.is_expired == 0 && course_basics.status == 1"
                            @click="goLogin">去学习</el-button>
                        <el-button type="text" round
                            v-if="isLogin == false && course_claim_info.learning_authority == 1 && course_basics.is_expired == 0 && course_basics.status == 1">暂不对公众开放</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == false && course_claim_info.learning_authority == 2 && course_basics.is_expired == 0 && course_basics.status == 1"
                            @click="goLogin">立即购买</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == false && course_claim_info.learning_authority == 3 && course_basics.is_expired == 0 && course_basics.status == 1"
                            @click="goLogin">立即参与</el-button>
                        <!-- 登录没有课程权限 -->
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 0 && course_basics.is_expired == 0 && course_basics.status == 1"
                            @click="learns($route.query.course_id, course_basics.default_class_id)">去学习</el-button>
                        <el-button type="info" round disabled
                            v-if="isLogin == true && course_claim_info.learning_authority == 1 && user.in_list < 1 && course_basics.is_expired == 0 && course_basics.status == 1">暂不对公众开放</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 2 && user.in_list < 1 && course_basics.is_expired == 0 && course_basics.status == 1"
                            @click="snbuyNow">立即购买</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 3 && user.in_list > 0 && user.user_class_id == 0 && course_basics.is_expired == 0 && course_basics.status == 1"
                            @click="participateImmediately">立即参与</el-button>
                        <!-- 登录有课程权限 -->
                        <!-- <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 0 && user.in_list > 0 && user.user_class_id > 0"
                            @click="goLearns($route.query.course_id, course_basics.default_class_id)">去学习</el-button> 
                            course_basics.is_expired == 0 && course_basics.status == 1
                        -->
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 1 && user.in_list > 0 && course_basics.is_expired == 0"
                            @click="goLearns($route.query.course_id, course_basics.default_class_id)">去学习</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 2 && user.in_list > 0 && course_basics.is_expired == 0"
                            @click="goLearns($route.query.course_id, course_basics.default_class_id)">去学习</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 3 && user.in_list > 1 && user.user_class_id > 0 && course_basics.is_expired == 0"
                            @click="goLearns($route.query.course_id, course_basics.default_class_id)">去学习</el-button>
                        <el-button type="primary" round
                            v-if="isLogin == true && course_claim_info.learning_authority == 3 && user.in_list == 1 && user.user_class_id > 0 && course_basics.is_expired == 0"
                            @click="goLearns($route.query.course_id, course_basics.default_class_id)">已参与，去学习</el-button>
                        <span class="upgradeCertification" @click="upgradeCertification"
                            v-if="isLogin == true && course_claim_info.learning_authority == 3 && user.in_list == 1 && user.user_class_id > 0 && course_basics.is_expired == 0">升级认证模式</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomWrap">
            <div class="bottomCourseWrap main">
                <div class="bottomLeftWrap">
                    <ul>
                        <template v-for="(item, index) in leftMenuList">
                            <li :key="index" @click="changeMenu(item)" v-if="item.is_show == 1" class="menuItem"
                                :class="nowActive == item.retype ? 'chooseItem' : ''">
                                <i :class="'iconfont ' + item.icon"></i>{{ item.title }}
                            </li>
                        </template>
                        <li class="menuItem certificate" v-if="course_claim_info.certificate_id > 0 && isLogin == true"
                            @click="isState($route.query.course_id, course_claim_info.certificate_id)"><img
                                src="./assets/images/certificate_icon.png" alt="">证书</li>
                        <li class="menuItem certificate" v-if="course_claim_info.certificate_id > 0 && isLogin == false"
                            @click="goLogin"><img src="./assets/images/certificate_icon.png" alt="">证书</li>
                    </ul>
                </div>
                <div class="bottomRightWrap">
                    <NewCourseInfo ref="CourseInfo" v-if="nowActive == 'introduction'"
                        :course_customize="course_customize" :iShow="iShow"></NewCourseInfo>
                    <CoursePlan ref="CoursePlan" v-if="nowActive == 'arrangement'" :modelFlag="course_basics.is_stage"
                        :expired="course_basics.is_expired" :in_list="user.in_list"></CoursePlan>
                    <CourseData ref="CourseData" :ssid="ssid" v-if="nowActive == 3"></CourseData>
                    <CourseNote ref="CourseNote" v-if="nowActive == 4"></CourseNote>
                    <CourseEval ref="CourseEval" v-if="nowActive == 'evaluation'" :is_expired="course_basics.is_expired"
                        :in_list="user.in_list"></CourseEval>
                </div>
            </div>
        </div>
        <!-- <AddList ref="AddList" :project_id="course.project_id"></AddList> -->
        <ScoreDia ref="ScoreDia"></ScoreDia>
        <playRemind ref="playRemind"></playRemind>
        <authentication ref="authentication" @superiorLearn="learnEvent" @authenticationMode="snbuyNow">
        </authentication>
        <certificationUpgrade ref="certificationUpgrade" @goPlaceAnOrder="goPlaceAnOrder"></certificationUpgrade>
        <!-- 证书状态 -->
        <CertificateStatus ref="CertificateStatus" @goUpgrade="goPlaceAnOrder" @goBuy="snbuyNow"></CertificateStatus>
        <applyState ref="applyState" @tipsShow="tipsShow"></applyState>
        <informationPrompt ref="informationPrompt" @determine="determine"></informationPrompt>
    </div>
    <nolearn v-else></nolearn>
</template>

<script>
// import AddList from "./components/infoCom/addList.vue";
import ScoreDia from "./components/infoCom/scoreDia.vue";
// import CourseInfo from "./components/courseInfo.vue";
import NewCourseInfo from "./components/NewCourseInfo";
import CoursePlan from "./components/coursePlan.vue";
import CourseData from "./components/courseData.vue";
import CourseNote from "./components/courseNote.vue";
import CourseEval from "./components/courseEval.vue";
import nolearn from "./components/nolearn.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false });
import { courseInfo, portalInfo, webInfo, checkLogin, addclass, certificateStatus } from "./assets/api.js";
import playRemind from "./components/playRemind.vue";
import authentication from "./components/authentication.vue";
import certificationUpgrade from "./components/certificationUpgrade.vue";
import CertificateStatus from "./components/certificate_status.vue";
import applyState from "./components/applyState.vue";
import informationPrompt from "./components/informationPrompt.vue";
export default {
    components: {
        // AddList,
        ScoreDia,
        NewCourseInfo,
        CoursePlan,
        CourseData,
        CourseNote,
        CourseEval,
        nolearn,
        playRemind,
        authentication,
        certificationUpgrade,
        CertificateStatus,
        applyState,
        informationPrompt
    },
    data () {
        return {
            leftMenuList: [
                // {
                //   id: 1,
                //   name: "课程介绍",
                //   icon: "icon-kcjs",
                // },
            ],
            nowActive: "introduction",
            is_stage: "",
            projectFlag: true,
            msg: "",
            ssid: "",
            course_customize: [],
            course_basics: {},  // 课程基本信息
            course_related: null,
            course_settings: {},
            iShow: false,
            user: {},
            isLogin: false,
            course_claim_info: {},
            course_card_switch: 0
        };
    },

    mounted () {
        let timer = setInterval(() => {
            if (sessionStorage.getItem('info_name') != null && JSON.parse(sessionStorage.getItem('info_name')).school_id != 0) {
                this.course_card_switch = sessionStorage.getItem('schoolInfo') ? JSON.parse(sessionStorage.getItem('schoolInfo')).course_card_switch : JSON.parse(sessionStorage.getItem('info_name')).course_card_switch
                this.portalInfo();
                if (localStorage.getItem('infos')) {
                    this.isLogin = true
                } else {
                    this.isLogin = false
                }
                clearInterval(timer)
            } else if (sessionStorage.getItem('schoolInfo')) {
                this.course_card_switch = sessionStorage.getItem('schoolInfo') ? JSON.parse(sessionStorage.getItem('schoolInfo')).course_card_switch : JSON.parse(sessionStorage.getItem('info_name')).course_card_switch
                this.portalInfo();
                if (localStorage.getItem('infos')) {
                    this.isLogin = true
                } else {
                    this.isLogin = false
                }
                clearInterval(timer)
            }
        }, 100);
    },
    watch: {
        $route: {
            handler: function () {
                this.nowActive = "introduction";
                this.portalInfo()
            },
            deep: true,
        },
    },
    methods: {
        // 消息提示框
        tipsShow (e) {
            this.$refs.informationPrompt.openInit(e)
        },
        // 消息框确定
        determine (e) {
            this.$refs.applyState.doSubmit(e)
        },
        // 判断证书状态
        isState (course_id, certificate_id) {
            let params = {
                course_id,
                certificate_id
            }
            certificateStatus(params).then(res => {
                if (res.code == 0) {
                    let status = res.data.cert_test.status
                    let certificate_type = res.data.cert_test.certificate_type
                    if (certificate_type == 2) {
                        this.$refs.CertificateStatus.openInit(certificate_type)
                    } else {

                        if (status == 10 || status == 9 || status == 8 || status == 7) {
                            this.$refs.CertificateStatus.openInit(certificate_type, status)
                        } else if (status == 5 || status == 6) {
                            this.$refs.CertificateStatus.openInit(certificate_type, status, res.data.cert_test.copywriting)
                        } else {
                            this.$refs.applyState.openInit(res.data.cert_test, this.course_basics.course_title)
                        }
                    }

                }
            })
        },
        // 登录没有权限——先学后付
        participateImmediately () {
            this.$refs.authentication.openInit(this.course_claim_info.price)
        },
        // 登录没有权限——立即购买
        snbuyNow () {
            if (this.course_claim_info.param_project_no == '') {
                this.$refs.playRemind.openInit()
            } else {
                this.goPlaceAnOrder()
            }
        },
        // 升级认证模式
        upgradeCertification () {
            this.$refs.certificationUpgrade.openInit(this.course_claim_info.price)
        },
        // 去下单
        goPlaceAnOrder () {
            if (this.course_basics.status == 0) {
                this.$message.warning('改课程已下架，请选择其它课程学习！！！');
                setTimeout(() => {
                    this.$router.push('/search')
                }, 2000);
                return
            }
            this.$router.push({
                path: '/pay',
                query: {
                    course_id: this.$route.query.course_id,
                    claim_id: this.course_claim_info.claim_id
                }
            })
        },
        goLogin () {
            if (window.location.href.indexOf('localhost') > -1) {
                window.location.href = 'http://localhost:8080/login/#/';
            } else {
                let hostname = window.location.hostname;
                window.location.href =
                    '//' + hostname + '/login/#/';
            }
        },
        portalInfo () {
            let params = {
                course_id: this.$route.query.course_id,
            };
            webInfo(params).then(res => {
                if (res.code == 0) {
                    this.user = res.data.course_stable.user
                    sessionStorage.setItem('course', JSON.stringify(this.user))
                    // console.log(this.user, 123456789);
                    this.course_customize = res.data.course_customize;
                    this.course_basics = res.data.course_stable.course_basics
                    this.course_related = res.data.course_stable.course_related;
                    this.course_claim_info = res.data.course_stable.course_claim_info
                    this.course_settings = res.data.course_stable.course_settings;
                    this.leftMenuList = []
                    if (this.course_settings.length > 0) {
                        this.course_settings.map((item) => {
                            if (item.retype == "introduction") {
                                this.$set(item, "icon", "icon-kcjs");
                            } else if (item.retype == "arrangement") {
                                this.$set(item, "icon", "icon-kjap");
                            } else if (item.retype == "evaluation") {
                                this.$set(item, "icon", "icon-kcpj1");
                            }
                            this.leftMenuList.push(item);
                        });
                    }
                } else {
                    this.msg = res.message
                }
            })
            // portalInfo(params).then((res) => {
            //     if (res.code == 0) {
            //         this.course_customize = res.data.course_customize;
            //         let iShow = this.course_customize.findIndex(item => item.is_show == 1)
            //         this.iShow = iShow == -1 ? true : false
            //         this.course_basics = res.data.course_stable.course_basics;
            //         this.course_related = res.data.course_stable.course_related;
            //         this.course_settings = res.data.course_stable.course_settings;
            //         // console.log(this.course_related);
            //         if (this.course_settings.length > 0) {
            //             this.course_settings.map((item) => {
            //                 if (item.retype == "introduction") {
            //                     this.$set(item, "icon", "icon-kcjs");
            //                 } else if (item.retype == "arrangement") {
            //                     this.$set(item, "icon", "icon-kjap");
            //                 } else if (item.retype == "evaluation") {
            //                     this.$set(item, "icon", "icon-kcpj1");
            //                 }
            //                 this.leftMenuList.push(item);
            //             });
            //         }
            //         // console.log(this.leftMenuList);
            //     }
            // });
        },
        learnEvent () {
            this.learns(this.$route.query.course_id, this.course_basics.default_class_id)
        },
        learns (id, cst) {
            let params = {
                course_id: id,
                teach_class_id: cst
            }
            addclass(params).then(res => {
                if (res.code == 0) {
                    this.$router.push({
                        path: "/newlearn",
                        query: {
                            course_id: id,
                            cst_id: cst,
                        },
                    });
                }
            })

        },
        goLearns (id, cst) {
            this.$router.push({
                path: "/newlearn",
                query: {
                    course_id: id,
                    cst_id: cst,
                },
            });
        },
        plane (id) {
            this.$router.push({
                path: "plan_detail",
                query: {
                    project_id: id,
                    metaType: this.$route.query.metaType,
                },
            });
        },
        add () {
            this.$refs.AddList.openInit(this.course.end_time, this.course.in_list);
        },
        changeMenu (item) {
            this.nowActive = item.retype;
            // if (item.id == "introduction") {
            //     this.getcourseInfo(2);
            // }
        },
        // getcourseInfo (type) {
        //     NProgress.start();
        //     let params = {
        //         course_id: this.$route.query.course_id,
        //     };
        //     courseInfo(params).then((res) => {
        //         if (res.code == 0) {
        //             // console.log(res);
        //             NProgress.done();
        //             this.course = res.data;
        //             // this.$refs.CoursePlan.in_list = res.data.in_list
        //             this.ssid = res.data.sou_school_id;
        //             if (this.course.pro_course_list.length == 0) {
        //                 this.projectFlag = false;
        //             }
        //             // this.$refs.CourseInfo.dataList=[]
        //             // this.$refs.CourseInfo.dataList = res.data;
        //             this.$nextTick(() => {
        //                 window.sessionStorage.setItem("course", JSON.stringify(res.data));
        //             });
        //             if (type == 1) {
        //                 if (res.data.in_list != 0) {
        //                     this.nowActive = "introduction"; //记得改为arrangement
        //                 }
        //             }
        //         } else {
        //             this.msg = res.message;
        //         }
        //     });
        // },
    },
};
</script>

<style scoped lang="scss">
.courseWrap {
    .newlearn {
        // background: #f6f7f8;
    }

    .newlearnWrap {
        // height: 380px;
        padding: 20px 20px 40px 20px;
        box-sizing: border-box;
        display: flex;
        background: #fff;
        border-radius: 8px;
        margin-top: 10px;

        .pic {
            width: 462px;
            height: 347px;
            border-radius: 8px;
            margin-right: 55px;
            overflow: hidden;
            background-color: rgba(255, 255, 255, .1);
            display: flex;
            justify-content: center;
            position: relative;
            background-size: cover;
            background-position: center;
            align-items: center;

            img {
                width: 100%;
                filter: blur(0px);
            }
        }

        .pic::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: inherit;
            filter: blur(5px);
            /* 调整这个值来改变模糊程度 */
        }

        .img {
            width: 462px;
            height: 260px;
            border-radius: 8px;
            margin-right: 55px;
            overflow: hidden;
            background-color: rgba(255, 255, 255, .1);
            display: flex;
            justify-content: center;
            position: relative;
            background-size: cover;
            background-position: center;

            // align-items: center;
            img {
                height: 100%;
                filter: blur(0px);
            }
        }

        .img::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: inherit;
            filter: blur(5px);
            /* 调整这个值来改变模糊程度 */
        }

        .newlearnRight {
            display: flex;
            flex-direction: column;
            width: calc(100% - 520px);
            position: relative;

            .newlearnRightTitle {
                color: #333333;
                font-size: 26px;
                line-height: 36px;
                font-weight: normal;
                margin-top: 4px;
                max-height: 72px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }

            .newlearnRightTime {
                color: #888;
                font-size: 14px;
                line-height: 20px;
                font-weight: normal;
                margin-top: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }

            .newlearnRighttype {
                color: #666;
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;
                margin-top: 6px;

                i {
                    margin-right: 6px;
                    font-size: 14px;
                    color: var(--change-color);
                }

                span {
                    margin-right: 30px;
                }
            }

            .major {
                font-size: 14px;
                line-height: 24px;
            }

            .rightBottom {
                display: flex;
                align-items: center;
                // justify-content: space-between;
                // position: absolute;
                // left: 0px;
                width: 100%;
                margin-top: 20px;

                // bottom: 106px;
                .upgradeCertification {
                    display: inline-block;
                    font-size: 14px;
                    color: #fff;
                    background: linear-gradient(to left, #F6D25C, #EB8435);
                    line-height: 34px;
                    padding: 0 36px;
                    border-radius: 17px;
                    margin-left: 10px;
                    cursor: pointer;
                }

                .bottomLeft {
                    max-width: 75%;

                    p {
                        color: #333;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 6px;
                    }

                    p:first-child {
                        height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    p:last-child {
                        max-height: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }

                .el-button.is-round {
                    padding: 10px 36px;

                    i {
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
            }

            .price {
                background: #F6F7F8;
                font-size: 24px;
                font-weight: bold;
                color: var(--change-color);
                line-height: 60px;
                border-radius: 6px;
                padding: 0 20px;
                box-sizing: border-box;
                margin: 10px 0 0 0;
            }
        }
    }

    .topWrap {
        height: 433px;

        .breadCrumb {
            padding: 20px 0;
            box-sizing: border-box;
            font-size: 14px;
        }

        .topInfo {
            height: 260px;
            padding-right: 30px;
            box-sizing: border-box;
            display: flex;

            img {
                width: 465px;
                height: 260px;
                border-radius: 8px;
                margin-right: 55px;
            }

            .infoRight {
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: calc(100% - 520px);

                .rightTop {
                    font-size: 16px;

                    .rightTitle {
                        font-size: 26px;
                        margin-bottom: 15px;
                        color: #333;
                    }

                    .rightTime {
                        margin-bottom: 15px;
                        color: #888;
                    }

                    .rightCate {
                        color: #666;

                        span {
                            margin-right: 30px;

                            i {
                                margin-right: 5px;
                            }
                        }

                        .scoreWrap {
                            position: relative;

                            img {
                                width: 36px;
                                height: 36px;
                                position: absolute;
                                right: -100%;
                                top: -50%;
                            }
                        }
                    }
                }

                .rightBottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;

                    .bottomLeft {
                        display: flex;

                        .bottomItem {
                            margin-right: 45px;
                            color: #666;
                            font-size: 14px;

                            span {
                                font-size: 18px;
                                color: #fe4e00;
                                font-weight: bold;
                            }

                            i {
                                color: #fe4e00;
                            }

                            &:last-child {
                                padding-left: 35px;
                                position: relative;

                                &::after {
                                    clear: both;
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 5px;
                                    width: 1px;
                                    height: 40px;
                                    background: #dedede;
                                }
                            }
                        }
                    }

                    .el-button.is-round {
                        padding: 10px 20px;

                        i {
                            font-size: 16px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .bottomWrap {
        // background: #fff;
        // border: 1px solid #fff;
        padding: 20px 0;
        box-sizing: border-box;

        .centerWrap {
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 0 20px rgba(5, 17, 55, 0.08);
            padding: 45px 25px 40px 30px;
            box-sizing: border-box;
            margin-top: -65px;
            position: relative;

            .el-icon-close {
                position: absolute;
                right: 25px;
                top: 30px;
                font-size: 20px;
                color: #9b9b9b;
                cursor: pointer;
            }

            .centerTitle {
                font-size: 20px;
                color: #333;
                margin-bottom: 30px;

                i {
                    font-size: 19px;
                    color: #bbbbbb;
                    margin-right: 10px;
                }

                .el-button {
                    font-size: 16px;
                    margin-left: 20px;
                    padding: 0;
                }
            }

            .centerItemWrap {
                margin-left: -35px;
                overflow: hidden;

                .centerItem {
                    width: 20%;
                    padding-left: 35px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    float: left;

                    img {
                        width: 100%;
                        height: 130px;
                        border-radius: 8px 8px 0 0;
                    }

                    div.ellWrap {
                        border-radius: 0 0 8px 8px;
                        border: 1px solid #eeeeee;
                        border-top: none;
                        color: #333;
                        font-size: 14px;
                        padding: 10px 10px 15px 10px;
                        box-sizing: border-box;
                        // height: 68px;
                        box-sizing: border-box;
                        overflow: hidden;

                        .el-tag {}

                        //文字超出两行省略
                        .ellipsis_two {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            min-height: 42px;
                            max-height: 42px;
                        }
                    }
                }
            }
        }

        .bottomCourseWrap {
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 0 20px rgba(5, 17, 55, 0.08);
            // margin-top: 20px;
            display: flex;

            .bottomLeftWrap {
                padding: 30px 33px 30px 20px;
                box-sizing: border-box;
                border-right: 1px solid #eef1f3;

                ul {
                    .menuItem {
                        margin-bottom: 5px;
                        padding: 7px 20px;
                        border-radius: 6px;
                        box-sizing: border-box;
                        color: #333;
                        font-size: 16px;
                        cursor: pointer;
                        width: 145px;
                        height: 40px;

                        i {
                            color: #a6a6c1;
                            font-size: 18px;
                            margin-right: 10px;
                        }

                        &:hover {
                            background: #f8f8f8;
                        }
                    }

                    .certificate {
                        border: 1px solid #F5E0C8;
                        background: linear-gradient(to top, #FEF2E3, #FEE8D4);
                        color: #9F520F;

                        img {
                            margin-right: 10px;
                        }

                        &:hover {
                            background: linear-gradient(to top, #FEF2E3, #FEE8D4);
                        }
                    }

                    .chooseItem {
                        background: var(--change-color);
                        color: #fff;

                        i {
                            color: #fff;
                        }

                        &:hover {
                            background: var(--change-color);
                        }
                    }
                }
            }

            .bottomRightWrap {
                width: calc(100% - 200px);
            }
        }
    }

    .footerWrap {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

        .main {
            padding: 0 40px 0 223px;
            box-sizing: border-box;
        }

        .contentItem {
            padding: 15px 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            cursor: pointer;

            .leftItemNum {
                font-size: 20px;
                font-weight: bold;
                color: #b9bcc1;
                margin-right: 15px;
                width: 52px;
            }

            .leftItemImg {
                width: 34px;
                height: 34px;
                margin-right: 15px;
            }

            .rightItem {
                width: calc(100% - 90px);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .rightLeft {
                    padding-right: 65px;
                    box-sizing: border-box;

                    .leftTitle {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }

                    .leftInfo {
                        font-size: 14px;
                        color: #999999;

                        .tag {
                            border-radius: 4px;
                            line-height: 19px;
                            padding: 0 6px;
                            box-sizing: border-box;
                            border: 1px solid #d8d8d8;
                        }
                    }
                }

                .statusWrap {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 15px;
                    }

                    .btnWrap {
                        width: 96px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        background: #507fff;
                        color: #fff;
                        border-radius: 16px;
                    }
                }
            }

            &:hover {
                .leftTitle {
                    color: #507fff;
                }
            }
        }
    }
}
</style>
